import FeedbackSelectDialog from './FeedbackSelectDialog'
import { isDeletedThermostat, isDeletedVehicle } from '@/types/deletedDevice'

const VehicleReasons = [
  { display: 'Security concerns', value: 'security' },
  { display: 'Charge scheduling issues', value: 'charge_scheduling' },
  { display: 'Phantom drain', value: 'phantom_drain' },
  { display: 'Not useful', value: 'not_useful' },
  { display: 'Incorrect rates', value: 'incorrect_rates' },
  { display: 'I don’t own this vehicle', value: 'vehicle_not_owned' },
  { display: 'Other', value: 'other' },
]

const ThermostatReasons = [
  { display: 'Security concerns', value: 'security' },
  { display: 'I experienced scheduling issues', value: 'scheduling' },
  { display: 'Not useful', value: 'not_useful' },
  { display: 'I don’t own this thermostat', value: 'thermostat_not_owned' },
  { display: 'I found errors in the data you shared', value: 'data_errors' },
  { display: 'Other', value: 'other' },
]

const EVSEReasons = [
  { display: 'Security concerns', value: 'security' },
  { display: 'I experienced scheduling issues', value: 'scheduling' },
  { display: 'Not useful', value: 'not_useful' },
  { display: 'I don’t own this charger', value: 'charger_not_owned' },
  { display: 'I found errors in the data you shared', value: 'data_errors' },
  { display: 'Other', value: 'other' },
]

const DeviceDeletionFeedbackDialog = ({
  open,
  onClose,
  onSubmit,
  deletedDevice,
  ...restProps
}) => {
  const submit = (feedbackValue) => {
    onSubmit(feedbackValue)
    onClose()
  }

  const reasons = isDeletedVehicle(deletedDevice)
    ? VehicleReasons
    : isDeletedThermostat(deletedDevice)
    ? ThermostatReasons
    : EVSEReasons

  return (
    <FeedbackSelectDialog
      open={open}
      onClose={onClose}
      onSubmit={submit}
      feedbackInquiry="Can you tell us why you wanted to delete your device?"
      reasons={reasons}
    />
  )
}

export default DeviceDeletionFeedbackDialog
