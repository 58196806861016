import { X } from 'react-feather'
import { Text } from '@/components'

export default function XListItem(props: { text: string }) {
  return (
    <div className="flex gap-2 items-start">
      <X className="flex-shrink-0" color="#3A98FF" size={20} />
      <Text variant="body4">{props.text}</Text>
    </div>
  )
}
