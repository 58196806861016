import { useAppDispatch, useAppSelector } from '@/hooks'
import { customUserResourceCollection } from '@/reducers/customUser'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { selectUserEmail, selectUserFullName } from '@/selectors'
import { ResponseError } from '@/request/types'
import { useState } from 'react'
import { doLogout } from '@/actions/auth'
import { fetchAuthenticated } from '@/utils/fetch'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

export type ViewModelProps = {
  open: boolean
  onClose: () => void
}

const validationSchema = yup.object({
  name: yup.string().required().label('This'),
  email: yup.string().email().required().label('This'),
})

const useViewModel = (props: ViewModelProps) => {
  const dispatch = useAppDispatch()
  const userId = useAppSelector((state) => state.user.user?.id)
  const fullName = useAppSelector(selectUserFullName)
  const email = useAppSelector(selectUserEmail)
  const saveLoading = useAppSelector((state) =>
    customUserResourceCollection.selectors.queryState.someLoading(state),
  )

  const [deletionConfirmationModalOpen, setDeletionConfirmationModalOpen] =
    useState(false)
  const [deletionConfirmationSurveyOpen, setDeletionConfirmationSurveyOpen] =
    useState(false)
  const [deletionError, setDeletionError] = useState(false)

  const handleLogout = () => {
    doLogout(dispatch)
    window.location.reload()
  }

  const handleDeleteAccountIntent = () => {
    setDeletionConfirmationModalOpen(true)
  }

  const handleDeleteAccount = () => {
    fetchAuthenticated('/current_user', { method: 'DELETE' }).then(
      (response) => {
        closeDeletionConfirmationModals()
        if (response.ok) {
          handleLogout()
        } else {
          setDeletionError(true)
        }
      },
    )
  }

  const closeDeletionConfirmationModals = () => {
    setDeletionConfirmationModalOpen(false)
    setDeletionConfirmationSurveyOpen(false)
  }

  const handleDeleteAccountAccepted = () => {
    setDeletionConfirmationModalOpen(false)
    setDeletionConfirmationSurveyOpen(true)
  }

  const getUpdateBody = (values: {
    name: string | undefined
    email: string | undefined
  }) => {
    const nameChanged = values.name !== fullName
    const emailChanged = values.email !== email
    return {
      ...(nameChanged ? { full_name: values.name } : undefined),
      ...(emailChanged ? { username: values.email } : undefined),
    }
  }

  const formik = useFormik({
    initialValues: {
      name: fullName,
      email: email,
    },
    validationSchema,
    onSubmit: (values) => {
      if (!userId) {
        return
      }
      dispatch(
        customUserResourceCollection.actions.update(
          userId,
          getUpdateBody(values),
        ),
      )
    },
  })

  const errors: ResponseError | null = useAppSelector((state) => {
    if (!userId) {
      return null
    }
    return (
      customUserResourceCollection.selectors.queryState
        .select(state, {
          id: userId,
          body: JSON.stringify(getUpdateBody(formik.values)),
        })
        .map((queryState) => queryState.error)
        .find((e) => Boolean(e)) ?? null
    )
  })

  const valuesHaventChanged =
    formik.values.name === fullName && formik.values.email === email
  const saveDisabled = valuesHaventChanged || !formik.isValid || saveLoading

  return {
    ...props,
    formik,
    fullName,
    email,
    saveDisabled,
    errors,
    handleLogout,
    handleDeleteAccountIntent,
    handleDeleteAccountAccepted,
    deletionConfirmationModalOpen,
    closeDeletionConfirmationModals,
    deletionConfirmationSurveyOpen,
    handleDeleteAccount,
    deletionError,
  }
}

const useMockViewModel = () => {
  const formik = useFormik({
    initialValues: {
      name: 'Colin Murphy',
      email: 'colin@getoptiwatt.com',
    },
    validationSchema,
    onSubmit: () => {},
  })

  return {
    formik: formik,
    fullName: 'Colin Murphy',
    email: 'colin@getoptiwatt.com',
    saveDisabled: false,
    errors: null,
    open: true,
    onClose: () => {},
    handleLogout: () => alert('Logged out'),
    handleDeleteAccountIntent: () => alert('Delete account intent'),
    handleDeleteAccountAccepted: () => alert('Delete account accepted'),
    handleDeleteAccount: () => alert('Delete account'),
    deletionConfirmationModalOpen: false,
    closeDeletionConfirmationModals: () =>
      alert('Close deletion confirmation modal'),
    deletionConfirmationSurveyOpen: false,
    deletionError: false,
  }
}

export default useViewModel
