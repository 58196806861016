import { Dialog, Grid, IconButton } from '@material-ui/core'
import { X } from 'react-feather'
import ConnectVehicleSelector from './Vehicle/ConnectVehicleSelector'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: theme.spacing(4),
  },
  iconButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  selectorArea: {
    maxHeight: 'max-content',
  },
  selectorTitle: {
    paddingLeft: theme.spacing(7),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },
}))

const ConnectVehicleDialog = ({ onClose, open }) => {
  const classes = useStyles()

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid container>
        <Grid item>
          <ConnectVehicleSelector
            titleStyle={classes.selectorTitle}
            selectorAreaStyle={classes.selectorArea}
            onTeslaConnectionEnd={onClose}
          />
        </Grid>
      </Grid>
      <IconButton className={classes.iconButton} onClick={onClose}>
        <X size={24} color="black" />
      </IconButton>
    </Dialog>
  )
}

export default ConnectVehicleDialog
