import React from 'react'
import { Box } from '@material-ui/core'
import { useAppSelector } from '../../hooks'
import HomeSettingsManagementCard from './HomeSettingsManagementCard'
import HomeSelectTaskCard from './HomeSelectTaskCard'
import ErrorAlerts from './ErrorAlerts'
import { clearHomeErrors } from '../../actions/homes'

export default function HomeCard() {
  const selectedHome = useAppSelector((state) => state.homes.selectedHome)
  return (
    <Box>
      {selectedHome ? <HomeSettingsManagementCard /> : <HomeSelectTaskCard />}
      <ErrorAlerts
        clearableErrors={[
          {
            selector: (state) => state.homes.errors,
            clearAction: clearHomeErrors,
          },
        ]}
      />
    </Box>
  )
}
