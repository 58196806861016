import { AdaptiveModal } from 'app-components'
import { Button, LoadingAnimation } from '@/components'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { useAppDispatch } from '@/hooks'
import { useUserSurveyOf } from '@/app/hooks/useUserSurveyOf'
import { SurveyResponse } from '@/types'
import { useState } from 'react'
import { selectUtility } from '@/actions/utilities'
import { selectPlan } from '@/actions/plans'
import { remoteConfig } from '@/firebase'

type Props = {
  open: boolean
  onClose: () => void
}

const AdminPanel = ({ open, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  // Fetch surveys using a custom hook
  const fetchSurveys = () => [
    useUserSurveyOf('Gexa Navigate To Switch 1'),
    useUserSurveyOf('Gexa Complete Switch 1'),
    useUserSurveyOf('Plan Switch Approval 1'),
    useUserSurveyOf('Insights Dismissal 1'),
    useUserSurveyOf('Rate Watch 1'),
  ]

  const surveys: (SurveyResponse | undefined)[] = fetchSurveys()

  const clearGexaSurveys = async () => {
    if (!remoteConfig) {
      return
    }

    setIsLoading(true)
    dispatch(
      selectUtility({
        utility_id: remoteConfig.getNumber('gexa_reset_utility_id'),
      }),
    )
    dispatch(
      selectPlan({ plan_id: remoteConfig.getNumber('gexa_reset_plan_id') }),
    )

    await dispatch(surveyResponsesCollection.actions.invalidate())

    for (const survey of surveys) {
      if (survey) {
        await dispatch(
          surveyResponsesCollection.actions.update(+survey.id, {
            completed: null,
            dismissed: null,
            response: null,
            last_seen: null,
          }),
        )
      }
    }

    await dispatch(surveyResponsesCollection.actions.invalidate())
    setIsLoading(false)
  }

  return (
    <AdaptiveModal
      title="Admin Panel"
      onClose={onClose}
      open={open}
      id="profile-admin-panel"
      hideCloseButton
    >
      <div className="flex flex-col h-full">
        <div className="grid grid-cols-1 p-4 gap-4">
          <Button
            disabled={isLoading}
            id="gexa-survey-reset"
            variant="primary-subtle"
            onClick={clearGexaSurveys}
          >
            GEXA Survey Reset
          </Button>
          {isLoading && <LoadingAnimation type="falling" centered />}
        </div>
        <div className="p-4 mt-auto">
          <Button id="close" variant="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </AdaptiveModal>
  )
}

export default AdminPanel
