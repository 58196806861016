import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  CircularProgress as MuiCircularProgress,
  IconButton,
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Plan, PlanType } from '../../../types/plan/'
import { spacing } from '@material-ui/system'
import { CreditCard, Globe, Home, Info, Layers } from 'react-feather'
import { useAppSelector } from '../../../hooks'
import { getValidUrl } from '../../../utils/objectUtilities'
import PlanTypeDialog from '../Plan/PlanTypeDialog'
import IconItemizedList from '../IconItemizedList'

const CircularProgress = styled(MuiCircularProgress)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    centered: {
      textAlign: 'center',
    },
    link: {
      color: theme.palette.blue?.main ?? '#426FFB',
    },
    infoIcon: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  }),
)

export default function PlanSettingsManagementCardContent() {
  const classes = useStyles()
  const [planType, setPlanType] = useState<PlanType | null>(null)
  const [typeDialogOpen, setTypeDialogOpen] = useState(false)
  const selectedPlan: Plan | null = useAppSelector(
    (state) => state.plans.selectedPlan,
  )
  const plansLoading = useAppSelector((state) => state.plans.isLoading)
  const { isLoading: utilitiesLoading } = useAppSelector(
    (state) => state.utilities,
  )
  const { isLoading: homesLoading } = useAppSelector((state) => state.homes)
  const loading =
    (plansLoading || utilitiesLoading || homesLoading) && !selectedPlan

  function openPlanTypeDialog(type: PlanType) {
    setPlanType(type)
    setTypeDialogOpen(true)
  }

  const RateType = () => {
    return (
      <>
        {selectedPlan?.types.map((type, index) => (
          <span key={index}>
            {type}
            <IconButton
              onClick={() => openPlanTypeDialog(type)}
              style={{ padding: '6px' }}
            >
              <Info className={classes.infoIcon} size={13} />
            </IconButton>
          </span>
        ))}
        {planType && (
          <PlanTypeDialog
            open={typeDialogOpen}
            handleClose={() => setTypeDialogOpen(false)}
            planType={planType}
          />
        )}
      </>
    )
  }

  return loading ? (
    <Box className="centered">
      <CircularProgress m={2} color="secondary" />
    </Box>
  ) : (
    <IconItemizedList
      items={[
        ...(selectedPlan?.name
          ? [{ icon: Layers, text: selectedPlan.name }]
          : []),
        ...(selectedPlan?.sector
          ? [{ icon: Home, text: selectedPlan.sector }]
          : []),
        ...(selectedPlan?.source
          ? [
              {
                icon: Globe,
                text: (
                  <a
                    className={classes.link}
                    href={getValidUrl(selectedPlan.source)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Plan Details
                  </a>
                ),
              },
            ]
          : []),
        ...(selectedPlan?.types
          ? [{ icon: CreditCard, text: <RateType /> }]
          : []),
      ]}
    />
  )
}
