import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { DashboardSections } from './DashboardCard'
import TitledProfileCard from './TitledProfileCard'

const useStyles = makeStyles((theme) =>
  createStyles({
    manageContainer: {
      width: '100%',
      textAlign: 'center',
    },
    manageText: {
      textAlign: 'center',
      textDecoration: 'underline',
      color: theme.palette.blue?.main,
      textTransform: 'none',
      padding: theme.spacing(0, 1),
      fontSize: '14px',
    },
    manageCard: {
      padding: theme.spacing(2),
    },
  }),
)

type ManageActionCardProps = {
  title: React.ReactNode
  children: React.ReactNode
  action: () => void
  id: string
}

function ManageActionCard({
  children,
  action,
  title,
  id,
}: ManageActionCardProps) {
  const classes = useStyles()

  return (
    <TitledProfileCard
      title={title}
      cardClasses={{ root: classes.manageCard }}
      pb={0}
    >
      <DashboardSections insertDividers spacing={2}>
        <Box>{children}</Box>
        <Box className={classes.manageContainer}>
          <Button onClick={action} id={id + '-manage-button'}>
            <Typography className={classes.manageText} variant="subtitle2">
              Manage
            </Typography>
          </Button>
        </Box>
      </DashboardSections>
    </TitledProfileCard>
  )
}

export default ManageActionCard
