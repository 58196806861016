import { Button, CardAction, CardActions, Grid, Modal } from '@/components'
import formatClasses from '@/utils/classes/formatClasses'
import './styles.scss'
import AlertMessage from '@/app/components/AlertMessage'

interface ConfirmationModalProps {
  open: boolean
  handleClose: () => void
  handleConfirm: () => void
  deletionError: boolean
}

const DeletionConfirmationModal = (props: ConfirmationModalProps) => {
  const classes = {
    button: formatClasses(['account-card-button']),
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      id="delete-account-confirmation-modal"
    >
      <Modal.Title>
        Are you sure you want to delete your Optiwatt account?
      </Modal.Title>
      <Grid gap="8px" flow="row">
        <CardActions>
          <CardAction type="primary">
            <Button
              variant="primary"
              className={classes.button}
              onClick={props.handleClose}
              id="delete-account-confirmation-modal-back-button"
            >
              Back
            </Button>
          </CardAction>
          <CardAction type="primary">
            <Button
              variant="secondary"
              className={classes.button}
              onClick={props.handleConfirm}
              important={true}
              id="delete-account-confirmation-modal-delete-button"
            >
              Delete Account
            </Button>
          </CardAction>
        </CardActions>
        <AlertMessage variant="error" show={props.deletionError}>
          There was an issue deleting your account. Try again later and contact
          Optiwatt support if the problem persists.
        </AlertMessage>
      </Grid>
    </Modal>
  )
}

export default DeletionConfirmationModal
