import React from 'react'
import PlanSettingsManagementCardContent from './PlanSettingsManagementCardContent'
import ManageActionCard from '../ManageActionCard'
import { useNavigation } from '@/app/hooks'

export default function PlanSettingsManagementCard() {
  const navigation = useNavigation()

  const goToManagePlan = () => {
    navigation.push('settings/plan')
  }

  return (
    <ManageActionCard
      title="Utility Plan"
      id="utility-plan-card"
      action={goToManagePlan}
    >
      <PlanSettingsManagementCardContent />
    </ManageActionCard>
  )
}
