import { useDispatch } from 'react-redux'
import { disableVehicleActions, getVehicles } from '@/actions/vehicles'
import { Button, Modal, Text } from '@/components'
import { useAppSelector } from '@/hooks'
import { AlertMessage, Link } from 'app-components'
import XListItem from '@/components/XListItem/XListItem'

type Props = {
  open: boolean
  onClose: () => void
  vehicleID: number
  oemName?: string
}

const VehicleDisableModal = ({ open, onClose, vehicleID, oemName }: Props) => {
  const errors = useAppSelector((state) => state.vehicles.errors)
  const dispatch = useDispatch()

  function handleClose() {
    onClose()
  }

  function handleSubmit() {
    // Disable all charge schedules and delete all trips for user
    dispatch(disableVehicleActions(vehicleID))
    dispatch(getVehicles())
    onClose()
  }

  return (
    <Modal
      id="disable-vehicle-dialog"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      title="Are you sure?"
      onClose={handleClose}
      open={open}
    >
      <Text variant="body4">Disabling your {oemName ?? 'vehicle'} will:</Text>
      <div className="flex flex-col gap-1 py-2">
        <XListItem text="Turn off user-scheduled charging and preconditioning" />
        <XListItem text="Delete saved trips" />
      </div>
      <Text variant="body4" className="mb-2">
        You can re-enable your vehicle at any time, but you will need to
        manually re-enroll in all features.
      </Text>
      <Text variant="body4">
        {' '}
        If you need help, our support team can assist you within 48 hours.
        Contact us at{' '}
        <Link href="mailto:support@optiwatt.com">
          support@optiwatt.com
        </Link> or <Link href="tel:855-967-1267">855-967-1267</Link>.
      </Text>
      {!!errors.detail && (
        <AlertMessage variant="error" className="mt-4">
          {errors.detail as string}
        </AlertMessage>
      )}
      <Modal.Actions>
        <Button id="cancel-button" onClick={handleClose} variant="secondary">
          Cancel
        </Button>
        <Button id="submit-button" onClick={handleSubmit} variant="primary">
          Disable
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default VehicleDisableModal
