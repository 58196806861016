import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box as MuiBox, Grid as MuiGrid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import { useAppDispatch, useAppSelector } from '../../../hooks/'
import SelectPlan from '../../components/Plan/SelectPlan'
import UserResourcesManagementSelectionGallery from '../../components/UserResourcesManagementSelectionGallery'
import UtilityProviderCard from '../../components/UtilityProviderCard'
import UtilityPlanCard from '../../components/UtilityPlanCard'
import HomeCard from '../../components/HomeCard'
import ErrorBoundary, { LocationTag } from '../../components/ErrorBoundary'
import { shouldLoadData } from '../../hooks/useDataLoader'
import { getVehicles } from '../../../actions/vehicles'

import { Text } from '../../../components'
import AccountCard from '@/app/features/settings/AccountCard'
import ManageProfileCard from '@/app/features/settings/ManageProfileCard'
import { updateRouteOnMobile } from '@/utils/messageUtils'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import SupportCard from 'features/settings/SupportCard/SupportCard'

const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    settingsContainer: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    secondColumn: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(6),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
  }),
)

export default function Profile() {
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const isEditingPlan = useAppSelector(
    (state) => state.manualPlans.isEditingPlan,
  )
  const vehiclesAreLoading = useAppSelector((state) => state.vehicles.isLoading)
  const vehiclesErrors = useAppSelector((state) => state.vehicles.errors)

  useEffect(() => {
    if (shouldLoadData(false, vehiclesAreLoading, vehiclesErrors)) {
      dispatch(getVehicles())
    }

    // needed for android since route events don't always get triggered correctly
    updateRouteOnMobile('/settings')
  }, [])

  if (isEditingPlan) {
    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        container
        p={{ xs: 5, lg: 10 }}
      >
        <Grid item xs={12} md={8} lg={8} xl={4}>
          <SelectPlan cancelOption={true} />
        </Grid>
      </Grid>
    )
  }

  return (
    <Box px={{ xs: 4, md: 5 }} py={6}>
      <Box px={3}>
        <Text variant="h2">Your profile</Text>
      </Box>
      <Grid container className={classes.settingsContainer} mt={6}>
        <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <ErrorBoundary
                location={LocationTag.UserResourcesManagementSelectionGallery}
                functionalityDescription="Device Gallery"
              >
                <UserResourcesManagementSelectionGallery />
              </ErrorBoundary>
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary
                location={LocationTag.UtilityProviderCard}
                functionalityDescription="Utility Provider Controls"
                fallbackOnCard
              >
                <UtilityProviderCard />
              </ErrorBoundary>
            </Grid>
            <Grid item xs={12}>
              <ErrorBoundary
                location={LocationTag.UtilityPlanCard}
                functionalityDescription="Utility Plan Controls"
                fallbackOnCard
              >
                <UtilityPlanCard />
              </ErrorBoundary>
            </Grid>
            {isNativeMobile() ? <></> : null}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={6} xl={4}>
          <Grid container spacing={6} className={classes.secondColumn}>
            <Grid item xs={12}>
              <ErrorBoundary
                location={LocationTag.HomeCard}
                functionalityDescription="Home Controls"
                fallbackOnCard
              >
                <HomeCard />
              </ErrorBoundary>
            </Grid>
            <Grid item xs={12}>
              <SupportCard />
            </Grid>
            <Grid item xs={12}>
              <ManageProfileCard />
            </Grid>
            <Grid item xs={12}>
              <AccountCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
