import { customUserResourceCollection } from '@/reducers/customUser'
import { RootState } from '@/store'
import { User } from '@/types'

export default function selectUserPropertyFromAllSources<K extends keyof User>(
  property: K,
): (state: RootState) => User[K] | undefined {
  return (state: RootState) => {
    const userId = state.user.user?.id
    if (!userId) {
      return undefined
    }
    return (
      customUserResourceCollection.selectors.selectById(state, userId)?.[
        property
      ] ?? state.user.user?.[property]
    )
  }
}

export const selectUserFullName = selectUserPropertyFromAllSources('full_name')
export const selectUserUsername = selectUserPropertyFromAllSources('username')
export const selectUserEmail = selectUserPropertyFromAllSources('username')
