import React from 'react'
import { Typography as MuiTypography, Box } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import styled from 'styled-components'
import ProfileCard, { Props as ProfileCardProps } from './ProfileCard'

const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontWeight: 'bold',
    },
  }),
)

export interface Props extends ProfileCardProps {
  title: React.ReactNode
}

export default function TitledProfileCard({ title, children, ...rest }: Props) {
  const classes = useStyles()

  return (
    <ProfileCard {...rest}>
      <Box>
        <Typography className={classes.title} variant="h6" mb={4}>
          {title}
        </Typography>
        {children}
      </Box>
    </ProfileCard>
  )
}
