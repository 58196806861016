import React from 'react'
import { useAppSelector } from '../../../hooks'
import TitledProfileCard from '../TitledProfileCard'
import PlanSelectTaskCardContent from './PlanSelectTaskCardContent'

export default function PlanSelectTaskCard() {
  const hasPendingPlan = useAppSelector(
    (state) => state.user.user?.profile?.is_plan_pending,
  )
  return (
    <TitledProfileCard title={hasPendingPlan ? 'Pending Plan' : 'Select Plan'}>
      <PlanSelectTaskCardContent planPending={hasPendingPlan ?? false} />
    </TitledProfileCard>
  )
}
