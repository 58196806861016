import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import { ClearableState } from '../../types/state'
import type { Error } from '../../types/apiResource'
import { useAppSelector, useAppDispatch } from '../../hooks'

const Alert = styled(MuiAlert)(spacing)

const useStyles = makeStyles(() => ({
  alert: {
    width: 'fit-content',
  },
}))

type ClearableErrorState = { errors: Error; clearDispatch: () => void }

function useClearableErrors(
  clearableErrors: ClearableState<Error>[],
): ClearableErrorState[] {
  const dispatch = useAppDispatch()
  return clearableErrors.map(({ selector, clearAction }) => ({
    errors: useAppSelector(selector),
    clearDispatch: () => dispatch(clearAction()),
  }))
}

type Props = {
  clearableErrors: ClearableState<Error>[]
}

export default function ErrorAlerts({ clearableErrors }: Props) {
  const classes = useStyles()
  const clearableErrorStates = useClearableErrors(clearableErrors)

  return (
    <>
      {clearableErrorStates
        .filter(({ errors }) => Object.keys(errors).length > 0)
        .map(({ errors, clearDispatch }, idx) => (
          <>
            {errors.detail && (
              <Alert
                key={idx}
                className={classes.alert}
                severity="error"
                onClose={clearDispatch}
                mt={2}
                px={5}
              >
                {errors.detail}
              </Alert>
            )}
          </>
        ))}
    </>
  )
}
