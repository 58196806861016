import React, { Component } from 'react'
import { Dialog, Grid } from '@material-ui/core'
import AddHome from './AddHome'

class AddHomeDialog extends Component {
  handleClose = () => {
    this.props.onClose()
  }

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={true}
        {...other}
      >
        <Grid align="center">
          <Grid item>
            <AddHome onClose={this.handleClose} />
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}

export default AddHomeDialog
