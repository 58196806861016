import React, { useEffect, useState } from 'react'
import HomeSettingsManagementCardContent from './HomeSettingsManagementCardContent'
import ManageActionCard from '../ManageActionCard'
import AddHomeDialog from '../Home/AddHomeDialog'
import {
  dismissInitialMobileUrl,
  requestLocationOnMobile,
} from '@/utils/messageUtils'
import { useUrlSearchParam } from '@/hooks'
import { useNavigation } from '@/app/hooks'

export default function HomeSettingsManagementCard() {
  const navigation = useNavigation()
  const startOpen = Boolean(useUrlSearchParam('location-open'))
  const [open, setOpen] = useState(startOpen)

  useEffect(() => {
    if (startOpen) {
      // remove query param after boot
      navigation.pushRemoveParam('location-open')
      requestLocationOnMobile('/settings?location-open=true')
    }
  }, [startOpen])

  const handleClose = () => {
    setOpen(false)
    // needed for a hack for location on mobile
    dismissInitialMobileUrl()
  }
  return (
    <ManageActionCard
      title="Home Address"
      action={() => setOpen(true)}
      id="address-card"
    >
      <HomeSettingsManagementCardContent />
      <AddHomeDialog open={open} onClose={handleClose} />
    </ManageActionCard>
  )
}
