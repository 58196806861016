import React from 'react'
import {
  Box,
  Typography as MuiTopography,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { startEditPlan } from '../../../actions/manualPlans'
import ContactUs from '@/app/components/ContactUs/ContactUs'

const Typography = styled(MuiTopography)(spacing)
const Button = styled(MuiButton)(spacing)
const CircularProgress = styled(MuiCircularProgress)(spacing)
const Centered = styled.div`
  text-align: center;
`

function PlanPendingContent() {
  const dispatch = useAppDispatch()
  return (
    <Box>
      <Typography variant="body2" gutterBottom>
        We are currently finding your plan from your utility bill, please{' '}
        <ContactUs /> to re-upload or check the status.
        <br />
        <br />
        Tired of waiting?
        <a
          href="javascript:;"
          onClick={() => {
            dispatch(startEditPlan())
          }}
        >
          {' '}
          Add plan manually.
        </a>
      </Typography>
    </Box>
  )
}

function PlanSelectContent() {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(
    (state) =>
      state.homes.isLoading ||
      state.plans.isLoading ||
      state.utilities.isLoading,
  )
  return (
    <Box>
      <Typography variant="body2" gutterBottom>
        Select your electric plan from the drop-down menu. This integrates your
        exact electricity rates.
      </Typography>
      <Centered>
        {loading ? (
          <CircularProgress m={2} color="secondary" />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(startEditPlan())
            }}
            mt={2}
          >
            Update
          </Button>
        )}
      </Centered>
    </Box>
  )
}

export interface Props {
  planPending: boolean
}

export default function PlanSelectTaskCardContent({ planPending }: Props) {
  return planPending ? <PlanPendingContent /> : <PlanSelectContent />
}
