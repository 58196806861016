import React, { useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Box } from '@material-ui/core'
import ResourceSelectionGallery, {
  GalleryRefHandle,
} from './ResourceSelectionGallery'
import { MenuAction, Resource } from './ResourceSelectionGallery/types'
import ConnectVehicleDialog from './ConnectVehicleDialog'
import VehicleDisableModal from './VehicleDisableModal'
import DeviceDeletionModal from './DeviceDeletionModal'
import DeviceDeletionFeedbackDialog from './DeviceDeletionFeedbackDialog'
import Loader from './layout/Loader'
import { clearVehicleErrors, selectVehicle } from '../../actions/vehicles'
import { selectedVehicle as getSelectedVehicle } from '../../selectors/vehicles'
import {
  clearDeleteVehicleErrors,
  submitDeleteVehicle,
} from '../../actions/vehicleAuth'
import { Vehicle } from '../../types/vehicle/'
import { DeletedVehicle } from '../../types/deletedVehicle'
import ErrorAlerts from './ErrorAlerts'
import { thermostatsCollection } from '../../reducers/thermostats'
import { Thermostat } from '../../types/thermostat'
import { findBrandByPortal } from '../../types/thermostatBrands'
import { useHistory } from 'react-router-dom'
import { VehicleCharger } from '@/types'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'
import * as Sentry from '@sentry/browser'
import FeedbackDialogContactForm from '@/authenticated/components/feedback/FeedbackDialogContactForm'
import {
  DeletedThermostat,
  deletedThermostatCollection,
} from '@/reducers/deletedThermostat'
import {
  DeletedVehicleCharger,
  deletedVehicleChargerCollection,
} from '@/reducers/deletedVehicleCharger'
import { justifyDeletion } from '@/actions/deletedVehicles'
import {
  DeletedDevice,
  isDeletedThermostat,
  isDeletedVehicle,
  isDeletedVehicleCharger,
} from '@/types/deletedDevice'
import { getVehicleDisplayModel, getVehicleDisplayName } from '@/utils/CarUtils'
import { useProgramDisabledDevices } from '@/hooks/useProgramDisabledDevices'

enum DialogType {
  VehicleDisableDialog,
  VehicleDeleteAndExplainDialog,
  DeletionFeedbackDialog,
  DeletionFreeformFeedbackDialog,
  ConnectVehicleDialog,
  ThermostatDelete,
  VehicleChargerDelete,
  None,
}

const DeletionConfirmations: DialogType[] = [
  DialogType.VehicleDeleteAndExplainDialog,
  DialogType.ThermostatDelete,
  DialogType.VehicleChargerDelete,
]

type DialogOpenSignal = {
  dialogType?: DialogType
  vehicle?: Vehicle
  deletedDevice?: DeletedDevice
  thermostat?: Thermostat
  vehicleCharger?: VehicleCharger
  open: boolean
}

type Props = {
  vehicles: Vehicle[]
  thermostats: Thermostat[]
  vehicleChargers: VehicleCharger[]
}

type DeletedDeviceType = 'vehicle' | 'thermostat' | 'charger'

function getSupportTicketTitle(deviceType?: DeletedDeviceType) {
  switch (deviceType) {
    case 'vehicle':
      return 'Feedback: User Deleted Vehicle'
    case 'thermostat':
      return 'Feedback: User Deleted Thermostat'
    case 'charger':
      return 'Feedback: User Deleted Smart Charger'
    default:
      return 'Feedback: User Deleted Device'
  }
}

function setDisabledResource(
  resource: Resource,
  isDisabled: boolean,
): Resource {
  return { ...resource, mode: isDisabled ? 'disabled' : resource.mode }
}

function ResourcesSelectionGallery({
  vehicles,
  thermostats,
  vehicleChargers,
}: Props) {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const galleryControlsRef: React.Ref<GalleryRefHandle> | null = useRef(null)
  // Dialog state
  const [dialogOpenSignal, _setDialogOpenSignal] = useState<DialogOpenSignal>({
    open: false,
  })
  const [deletedDeviceType, setDeletedDeviceType] =
    useState<DeletedDeviceType>()
  const { deviceIsDisabled } = useProgramDisabledDevices()
  const setDialogOpenSignal = (
    dialogType: DialogType,
    vehicle?: Vehicle,
    deletedDevice?: DeletedDevice,
    thermostat?: Thermostat,
    vehicleCharger?: VehicleCharger,
  ) => {
    if (dialogType === DialogType.None) {
      _setDialogOpenSignal({ open: false })
    }

    if (DeletionConfirmations.includes(dialogType)) {
      let selectedDeviceType: DeletedDeviceType = 'vehicle'
      if (dialogType === DialogType.ThermostatDelete) {
        selectedDeviceType = 'thermostat'
      } else if (dialogType === DialogType.VehicleChargerDelete) {
        selectedDeviceType = 'charger'
      }

      setDeletedDeviceType(selectedDeviceType)
    }

    _setDialogOpenSignal({
      dialogType,
      vehicle,
      deletedDevice,
      thermostat: thermostat,
      vehicleCharger: vehicleCharger,
      open: true,
    })
  }
  const onDialogClose = () => _setDialogOpenSignal({ open: false })

  const startFeedbackAfterDelete = ({
    payload: deletedDevice,
  }: {
    payload: DeletedDevice
  }) => {
    setDialogOpenSignal(
      DialogType.DeletionFeedbackDialog,
      undefined,
      deletedDevice,
    )
  }

  // Vehicle management state
  const selectedVehicle = useAppSelector((state) =>
    getSelectedVehicle(state.vehicles.vehicles),
  )
  const onMakeDefault = (vehicle: Vehicle) => () => {
    galleryControlsRef?.current?.scrollToStart()
    dispatch(
      selectVehicle({
        payload: vehicle,
        optimisticOptions: { revertTo: selectedVehicle },
      }),
    )
  }
  const vehicleToResource = (vehicle: Vehicle) => {
    const car = vehicle?.car
    const carModel = car?.car_model
    const displayName = car?.display_name
    const make = carModel?.make ?? 'Unset Make'
    const year = carModel?.year ?? ''
    const friendlyName = carModel?.friendly_name ?? ''
    const displayModel = getVehicleDisplayModel(vehicle)
    const imageUrl = car?.image_url
      ? `/dashboard/img/car_models${car.image_url}`
      : '/dashboard/img/car_models/covered-car.webp'
    let detailedName = displayName !== '' ? friendlyName : displayModel
    if (detailedName === 'modely') {
      detailedName = 'Model Y'
    }

    const details = `${year} ${detailedName}`
    const name = displayName !== '' ? displayName : make
    const id = `user-resource-gallery-vehicle-${make}-${vehicle.id}`

    const menuActions = [
      vehicles.length > 1 && {
        name: 'Make Default',
        action: onMakeDefault(vehicle),
        disabled: vehicle.id === selectedVehicle?.id,
      },
      {
        name: vehicle.is_charge_control_enabled ? 'Disable' : 'Disabled',
        action: () =>
          setDialogOpenSignal(DialogType.VehicleDisableDialog, vehicle),
        disabled: !vehicle.is_charge_control_enabled,
      },
      {
        name: 'Delete',
        action: () =>
          setDialogOpenSignal(
            DialogType.VehicleDeleteAndExplainDialog,
            vehicle,
          ),
      },
    ].filter(Boolean) as MenuAction[]

    return { name, id, details, image: imageUrl, menuActions }
  }

  const thermostatToResource = (thermostat: Thermostat) => ({
    name: thermostat.name ?? thermostat.portal,
    id: `user-resource-gallery-thermostat-${thermostat.portal}-${thermostat.id}`,
    details: 'Thermostat',
    image:
      findBrandByPortal(thermostat.portal)?.deviceImage ?? '/img/nest.webp',
    menuActions: [
      {
        name: 'Delete',
        action: () =>
          setDialogOpenSignal(
            DialogType.ThermostatDelete,
            undefined,
            undefined,
            thermostat,
          ),
      },
    ],
  })
  const vehicleChargerToResource = (vehicleCharger: VehicleCharger) => ({
    name: 'Chargepoint',
    id: `user-resource-gallery-vehicleCharger-${vehicleCharger.id}`,
    details: 'Charger',
    image: '/img/chargepoint-charger.webp',
    menuActions: [
      {
        name: 'Delete',
        action: () =>
          setDialogOpenSignal(
            DialogType.VehicleChargerDelete,
            undefined,
            undefined,
            undefined,
            vehicleCharger,
          ),
      },
    ],
  })

  const addNewDeviceResource = {
    name: 'Connect Device',
    details: 'Connect Device',
    id: 'resource-gallery-add-device',
    action: () => {
      history.push('/connect-device')
    },
    variant: 'action' as const,
  }

  const LogDeviceNotFound = () => {
    Sentry.captureMessage(
      `Error deleting ${deletedDeviceType}: ${deletedDeviceType} not found`,
    )
  }

  const onDeletionConfirm = () => {
    let dispatchEvent

    if (dialogOpenSignal.vehicle) {
      dispatchEvent = submitDeleteVehicle(dialogOpenSignal.vehicle)
    }

    if (dialogOpenSignal.thermostat) {
      dispatchEvent = thermostatsCollection.actions.remove(
        dialogOpenSignal.thermostat.id,
      )
    }

    if (dialogOpenSignal.vehicleCharger) {
      dispatchEvent = vehicleChargersCollection.actions.remove(
        `${dialogOpenSignal.vehicleCharger.id}`,
      )
    }

    if (!dispatchEvent) {
      LogDeviceNotFound()
      onDialogClose()
      return
    }

    dispatch(dispatchEvent).then(startFeedbackAfterDelete)
  }

  const onFeedbackSubmit = (feedback: string) => {
    const device = dialogOpenSignal.deletedDevice
    if (!device) {
      LogDeviceNotFound()
      onDialogClose()
      return
    }

    if (isDeletedVehicle(device)) {
      dispatchVehicleDelete(device as DeletedVehicle, feedback)
      return
    }

    if (isDeletedThermostat(device)) {
      dispatchThermostatDelete(device as DeletedThermostat, feedback)
      return
    }

    if (isDeletedVehicleCharger(device)) {
      dispatchChargerDelete(device as DeletedVehicleCharger, feedback)
      return
    }
  }

  const dispatchVehicleDelete = (
    deletedVehicle: DeletedVehicle,
    feedback: string,
  ) => {
    dispatch(justifyDeletion(deletedVehicle.id, feedback))
  }

  const dispatchThermostatDelete = (
    deletedThermostat: DeletedThermostat,
    feedback: string,
  ) => {
    dispatch(
      deletedThermostatCollection.actions.update(deletedThermostat.id, {
        reason: feedback,
      }),
    )
  }

  const dispatchChargerDelete = (
    deletedCharger: DeletedVehicleCharger,
    feedback: string,
  ) => {
    dispatch(
      deletedVehicleChargerCollection.actions.update(deletedCharger.id, {
        reason: feedback,
      }),
    )
  }

  const sortedVehicles = [...vehicles].sort((a) => -a.is_selected_vehicle)
  const vehicleResources: Resource[] = sortedVehicles
    .map(vehicleToResource)
    .map((res) => setDisabledResource(res, deviceIsDisabled('vehicle')))
  const thermostatResources: Resource[] = thermostats
    .map(thermostatToResource)
    .map((res) => setDisabledResource(res, deviceIsDisabled('thermostat')))
  const vehicleChargerResources: Resource[] = vehicleChargers
    .map(vehicleChargerToResource)
    .map((res) => setDisabledResource(res, deviceIsDisabled('vehicleCharger')))
  const resources = [
    ...vehicleResources,
    ...thermostatResources,
    ...vehicleChargerResources,
    addNewDeviceResource,
  ]

  return (
    <Box>
      <ResourceSelectionGallery
        resources={resources}
        ref={galleryControlsRef}
      />
      <ErrorAlerts
        clearableErrors={[
          {
            selector: (state) => state.vehicleAuth.errors,
            clearAction: clearDeleteVehicleErrors,
          },
          {
            selector: (state) => state.vehicles.errors,
            clearAction: clearVehicleErrors,
          },
        ]}
      />
      {/* Dialogs */}
      {dialogOpenSignal.open && (
        <>
          <ConnectVehicleDialog
            open={
              dialogOpenSignal.dialogType === DialogType.ConnectVehicleDialog
            }
            onClose={onDialogClose}
          />
          {Boolean(dialogOpenSignal.vehicle) && (
            <>
              <DeviceDeletionModal
                open={
                  dialogOpenSignal.dialogType ===
                  DialogType.VehicleDeleteAndExplainDialog
                }
                deviceType="vehicle"
                deviceName={`${dialogOpenSignal.vehicle?.car.car_model?.year} ${dialogOpenSignal.vehicle?.car.car_model?.friendly_name}`}
                onSubmit={onDeletionConfirm}
                onClose={onDialogClose}
              />
              {dialogOpenSignal.vehicle?.id && (
                <VehicleDisableModal
                  open={
                    dialogOpenSignal.dialogType ===
                    DialogType.VehicleDisableDialog
                  }
                  vehicleID={dialogOpenSignal.vehicle?.id}
                  onClose={onDialogClose}
                  oemName={getVehicleDisplayName(
                    dialogOpenSignal.vehicle,
                    true,
                  )}
                />
              )}
            </>
          )}
          {Boolean(dialogOpenSignal.thermostat) && (
            <DeviceDeletionModal
              open={dialogOpenSignal.dialogType === DialogType.ThermostatDelete}
              deviceType="thermostat"
              deviceName={dialogOpenSignal.thermostat?.portal ?? 'Thermostat'}
              onSubmit={onDeletionConfirm}
              onClose={onDialogClose}
            />
          )}
          {Boolean(dialogOpenSignal.vehicleCharger) && (
            <DeviceDeletionModal
              open={
                dialogOpenSignal.dialogType === DialogType.VehicleChargerDelete
              }
              deviceType="home"
              deviceName={'Chargepoint'}
              onSubmit={onDeletionConfirm}
              onClose={onDialogClose}
            />
          )}
          {Boolean(dialogOpenSignal.deletedDevice) && (
            <DeviceDeletionFeedbackDialog
              open={
                dialogOpenSignal.dialogType ===
                DialogType.DeletionFeedbackDialog
              }
              onClose={() =>
                setDialogOpenSignal(DialogType.DeletionFreeformFeedbackDialog)
              }
              onSubmit={onFeedbackSubmit}
              deletedDevice={dialogOpenSignal.deletedDevice}
            />
          )}
          <FeedbackDialogContactForm
            id="device-deletion-feedback"
            isOpen={
              dialogOpenSignal.dialogType ===
              DialogType.DeletionFreeformFeedbackDialog
            }
            title={
              "We've deleted your device. Let us know how we can better support your needs in the future."
            }
            supportTitle={getSupportTicketTitle(deletedDeviceType)}
            onClose={onDialogClose}
          />
        </>
      )}
    </Box>
  )
}

function MaybeResourceSelectionGallery() {
  const vehicles = useAppSelector((state) => state.vehicles.vehicles)
  const thermostats = useAppSelector(thermostatsCollection.selectors.selectAll)
  const vehicleChargers = useAppSelector(
    vehicleChargersCollection.selectors.selectAll,
  )

  return !vehicles ? (
    <Loader color="primary" />
  ) : (
    <ResourcesSelectionGallery
      vehicles={vehicles}
      thermostats={thermostats}
      vehicleChargers={vehicleChargers}
    />
  )
}

export default MaybeResourceSelectionGallery
