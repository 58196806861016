import {
  Box as MuiBox,
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core'
import GoogleMapReact from './GoogleMapReact'
import { Home } from 'react-feather'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useAppSelector } from '../../../hooks'
import IconItemizedList from '../IconItemizedList'

const Box = styled(MuiBox)(spacing)
const CircularProgress = styled(MuiCircularProgress)(spacing)
const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`

const useStyles = makeStyles(() =>
  createStyles({
    centered: {
      textAlign: 'center',
    },
  }),
)

export default function HomeSettings() {
  const classes = useStyles()
  const isLoading = useAppSelector((state) => state.homes.isLoading)
  const selectedHome = useAppSelector((state) => state.homes.selectedHome)

  let locationDisplayName
  if (selectedHome?.formatted_address) {
    locationDisplayName =
      selectedHome?.geocode_method !== 'lat_long'
        ? selectedHome?.formatted_address
        : `Near ${selectedHome?.formatted_address}`
  }

  return (
    <Box>
      <IconItemizedList
        items={[
          ...(locationDisplayName
            ? [{ icon: Home, text: locationDisplayName }]
            : []),
        ]}
      />

      {isLoading ? (
        <Box className={'h-[300px] flex items-center justify-center'}>
          <CircularProgress m={2} color="secondary" />
        </Box>
      ) : (
        <Box>
          <GoogleMapReactWrapper>
            <GoogleMapReact
              lat={selectedHome?.latitude ?? 0}
              lng={selectedHome?.longitude ?? 0}
              text={'Home'}
              defaultZoom={17}
              showRadius
            />
          </GoogleMapReactWrapper>
        </Box>
      )}
    </Box>
  )
}
