import { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import {
  Button as MuiButton,
  CircularProgress,
  FormControl as MuiFormControl,
  InputLabel,
  Paper,
  Select as MuiSelect,
  Typography as MuiTypography,
} from '@material-ui/core'
import { Layers } from 'react-feather'
import { spacing } from '@material-ui/system'
import { selectPlan } from '../../../actions/plans'
import { useDispatch, useSelector } from 'react-redux'
import ContactUs from '@/app/components/ContactUs/ContactUs'

const Button = styled(MuiButton)(spacing)

const Typography = styled(MuiTypography)(spacing)

const FormControlSpacing = styled(MuiFormControl)(spacing)

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`

const Select = styled(MuiSelect)(spacing)

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`

const BigLayers = styled(Layers)`
  width: 45px;
  height: 45px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`

function SelectPlanDropdown({ onClose, onCancel, ...restProps }) {
  const dispatch = useDispatch()
  const [plan_id, setPlanId] = useState('')
  const { selectedUtility, plans } = useSelector((state) => state.utilities)
  const { isLoading, errors } = useSelector((state) => state.plans)

  function handleChange(event) {
    setPlanId(event.target.value)
  }

  function handleSubmit(event) {
    event.preventDefault()
    dispatch(selectPlan({ plan_id: plan_id })).then(() => onClose())
  }

  function getName(plan) {
    if (plan.is_default) {
      return '[Most Common] ' + plan.name
    } else {
      return plan.name
    }
  }
  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      align="center"
      {...restProps}
    >
      <BigLayers />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {selectedUtility.name}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Select your energy plan from the drop-down menu.
      </Typography>

      {errors.detail ? (
        <div className="alert alert-danger" role="alert">
          {errors.detail}
        </div>
      ) : null}
      <form
        autoComplete="off"
        method="POST"
        id="addHomeForm"
        className="add-home-form"
        onSubmit={handleSubmit}
      >
        <FormControl required margin="normal" fullWidth>
          <InputLabel htmlFor="name">Utility Plan</InputLabel>
          <Select
            native
            required
            value={plan_id}
            onChange={handleChange}
            inputProps={{
              name: 'plan_id',
              id: 'plan_id',
            }}
          >
            <option value="" />
            {plans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {getName(plan)}
              </option>
            ))}
            ;
          </Select>
        </FormControl>
        {isLoading ? (
          <CircularProgress m={2} color="secondary" />
        ) : (
          <div>
            <Button
              data-ga-event-label="select-plan"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              mb={2}
            >
              Select Plan
            </Button>
            <Button
              onClick={onCancel}
              type="submit"
              fullWidth
              variant="contained"
              mb={2}
            >
              Cancel
            </Button>
            <Typography component="h2" variant="body1" align="center" mt={2}>
              Still need help? Please <ContactUs />.
            </Typography>
          </div>
        )}
      </form>
    </Wrapper>
  )
}

SelectPlanDropdown.defaultProps = {
  onClose: () => {},
  onCancel: () => {},
}

export default withTheme(SelectPlanDropdown)
