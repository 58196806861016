import React from 'react'
import styled from 'styled-components'

import { CircularProgress as MuiCircularProgress, Box } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import { Globe, Map, Zap } from 'react-feather'
import { getValidUrl } from '../../../utils/objectUtilities'
import { useAppSelector } from '../../../hooks'
import IconItemizedList from '../IconItemizedList'
import toExternalLink from '@/utils/to-external-link'

const CircularProgress = styled(MuiCircularProgress)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: theme.palette.blue?.main ?? '#426FFB',
    },
    centered: {
      textAlign: 'center',
    },
  }),
)

export default function UtilitySettingsManagementCardContent() {
  const classes = useStyles()
  const { isLoading: homesAreLoading } = useAppSelector((state) => state.homes)
  const { selectedUtility, isLoading: utilitiesAreLoading } = useAppSelector(
    (state) => state.utilities,
  )

  return (homesAreLoading || utilitiesAreLoading) && !selectedUtility ? (
    <Box className={classes.centered}>
      <CircularProgress m={2} color="primary" />
    </Box>
  ) : (
    <IconItemizedList
      items={[
        ...(selectedUtility?.name
          ? [{ icon: Zap, text: selectedUtility.name }]
          : []),
        ...(selectedUtility?.place
          ? [{ icon: Map, text: selectedUtility.place }]
          : []),
        ...(selectedUtility?.website
          ? [
              {
                icon: Globe,
                text: (
                  <div className="overflow-x-hidden text-ellipsis">
                    <a
                      className={classes.link}
                      href={toExternalLink(
                        getValidUrl(selectedUtility.website),
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedUtility.website}
                    </a>
                  </div>
                ),
              },
            ]
          : []),
      ]}
    />
  )
}
