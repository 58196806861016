import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { logException } from '../../../utils/exceptionUtilities'

export default function PlanTypeDialog(props) {
  function getPlanType(planType) {
    const planDescriptionMap = {
      'Time-of-use':
        'Time-of-use rates charge a certain rate for energy use at peak hours and a lower rate during off-peak hours (defined by the utility).',
      Tiered:
        'Tiered rate plans set one rate for energy use up to a certain baseline amount, then increase the rate for energy used above that amount to the next tier.',
      Flat: 'Flat rates allow you to use as much electricity as you want at any time of the day for one flat rate per kilowatt-hour (kWh) of energy.',
      'Real Time':
        'Real time plans have continuously changing rates over a specific time period.',
    }

    if (planType in planDescriptionMap) {
      return planDescriptionMap[planType]
    } else {
      logException(`No plan type "${planType}" found for PlanTypeDialog`)
      return "Sorry, we're still working on this part of the application!"
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="plan-type-dialog"
    >
      <DialogTitle id="plan-type-dialog">
        {`What is a "${props.planType}" electricity plan?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: '15px' }}>
          {getPlanType(props.planType)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
