import { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { addHome } from '../../../actions/homes'
import {
  Button as MuiButton,
  CircularProgress,
  FormControl as MuiFormControl,
  Paper,
  Typography as MuiTypography,
} from '@material-ui/core'
import { Home } from 'react-feather'
import { spacing } from '@material-ui/system'
import GoogleMapsSearchWithMap from '../forms/GoogleMapsSearchWithMap'
import { useDispatch } from 'react-redux'
import { logEvent } from '../../../logging'
import { useAppSelector } from '@/hooks'
import { getUtilities } from '@/actions/utilities'
import { getPlans } from '@/actions/plans'
import { getCurrentUser } from '@/actions/user'

const Button = styled(MuiButton)(spacing)

const Typography = styled(MuiTypography)(spacing)

const FormControlSpacing = styled(MuiFormControl)(spacing)

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`

const BigHome = styled(Home)`
  width: 45px;
  height: 45px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`

function AddHome({ onClose, ...restProps }: { onClose: () => void }) {
  const home = useAppSelector((state) => state.homes.selectedHome)
  const dispatch = useDispatch()
  const [placeId, setPlaceId] = useState('')
  const [location, setLocation] = useState<{ lat?: number; lng?: number }>()
  const { isLoading, errors } = useAppSelector((state) => state.homes)

  function handleChange(
    event?: React.ChangeEvent<unknown>,
    locationObj?: {
      place_id: string
      location?: { lat?: number; lng?: number }
    },
  ) {
    if (!locationObj?.place_id) return
    setPlaceId(locationObj.place_id)
    setLocation(locationObj?.location ?? undefined)
  }

  function handleSubmit(event: React.FormEvent<unknown>) {
    event.preventDefault()

    logEvent('addHome')

    const form: {
      google_place_id: string
      location?: { latitude: number; longitude: number }
    } = { google_place_id: placeId }
    if (location?.lat && location?.lng) {
      form.location = { latitude: location.lat, longitude: location.lng }
    }
    const promise = dispatch(addHome(form)) as unknown as Promise<unknown>
    promise.then(async () => {
      await Promise.all([
        dispatch(getUtilities()),
        dispatch(getPlans()),
        dispatch(getCurrentUser()),
      ])
      onClose()
    })
  }

  const initialLocaiton = {
    address: home?.formatted_address,
    lat: home?.latitude,
    lng: home?.longitude,
    precise: home?.geocode_method === 'google_place_id',
  }

  return (
    <Wrapper {...restProps}>
      <BigHome />
      <Typography variant="h4" align="center" gutterBottom>
        Add Home
      </Typography>
      <Typography variant="body1" align="center">
        Provide your address or select a location on the map so we can identify
        when you have plugged in at home. This also allows us to find your
        utility provider.
      </Typography>
      {errors.detail ? (
        <div className="alert alert-danger" role="alert">
          {errors.detail as string}
        </div>
      ) : null}
      <form
        method="POST"
        id="addHomeForm"
        className="add-home-form"
        onSubmit={handleSubmit}
      >
        <FormControl margin="normal" required fullWidth>
          <GoogleMapsSearchWithMap
            initialLocation={initialLocaiton}
            handleChange={handleChange}
          />
        </FormControl>
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            data-ga-event-label="add-home"
            id="add-home-card-buton"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            mb={2}
          >
            Add Home
          </Button>
        )}
      </form>
    </Wrapper>
  )
}

// https://blog.bitsrc.io/understanding-react-default-props-5c50401ed37d
AddHome.defaultProps = {
  onClose: () => {},
}

export default withTheme(AddHome as never)
