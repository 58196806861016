import {
  Button,
  CardAction,
  CardActions,
  Flex,
  Modal,
  TextField,
} from '@/components'
import { FormikProps } from 'formik'
import { AlertMessage } from '@/app/components'
import { ResponseError } from '@/request/types'
import DeletionConfirmationModal from './DeletionConfirmationModal'
import useViewModel, { ViewModelProps } from './useViewModel'
import FeedbackMessageSurvey from '@/components/FeedbackMessageSurvey'

type ComponentProps = ViewModelProps & {
  formik: FormikProps<{ name: string | undefined; email: string | undefined }>
  fullName?: string
  email?: string
  saveDisabled: boolean
  errors: ResponseError | null
  handleLogout: () => void
  handleDeleteAccountIntent: () => void
  handleDeleteAccountAccepted: () => void
  deletionConfirmationModalOpen: boolean
  closeDeletionConfirmationModals: () => void
  deletionConfirmationSurveyOpen: boolean
  handleDeleteAccount: () => void
  deletionError: boolean
}

function EditAccountModalComponent(props: ComponentProps) {
  const handleDeleteAccountClick = () => {
    props.onClose()
    props.handleDeleteAccountIntent()
  }

  return (
    <>
      <Modal id="edit-account-modal" open={props.open} onClose={props.onClose}>
        <Modal.Title>Edit Account</Modal.Title>
        <Flex container direction="column" gap="12px">
          <Flex item>
            <TextField
              className="w-full"
              name="name"
              label="Full Name"
              placeholder="Codi Burley"
              value={props.formik.values.name}
              error={Boolean(props.formik.errors.name)}
              helperText={props.formik.errors.name}
              onChange={props.formik.handleChange}
            />
          </Flex>
          <Flex item>
            <TextField
              className="w-full"
              name="email"
              label="Email"
              placeholder={props.formik.initialValues.email}
              value={props.formik.values.email}
              error={Boolean(props.formik.errors.email)}
              helperText={props.formik.errors.email}
              onChange={props.formik.handleChange}
            />
          </Flex>
          <Flex item>
            <AlertMessage variant="error" show={Boolean(props.errors)}>
              {JSON.stringify(props.errors?.response)}
            </AlertMessage>
          </Flex>

          <Flex item>
            <CardActions>
              <CardAction type="primary">
                <Button
                  id="account-edit-cancel-button"
                  variant="secondary"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </CardAction>
              <CardAction type="primary">
                <Button
                  id="account-edit-save-button"
                  variant="primary"
                  important={true}
                  onClick={() => props.formik.handleSubmit()}
                  disabled={props.saveDisabled}
                >
                  Save Changes
                </Button>
              </CardAction>
            </CardActions>
          </Flex>
          <div className="flex justify-center items-center pt-5 mt-1 border-t-[1px] border-solid border-[#B4B4B4]">
            <button
              id="account-card-delete-account-button"
              className="bg-transparent border-none underline hover:cursor-pointer text-[#FB5842] font-bold text-base"
              type="button"
              onClick={handleDeleteAccountClick}
            >
              Delete Account
            </button>
          </div>
        </Flex>
      </Modal>
      <DeletionConfirmationModal
        open={props.deletionConfirmationModalOpen}
        handleClose={props.closeDeletionConfirmationModals}
        handleConfirm={props.handleDeleteAccountAccepted}
        deletionError={props.deletionError}
      />
      <FeedbackMessageSurvey
        open={props.deletionConfirmationSurveyOpen}
        onClose={props.closeDeletionConfirmationModals}
        onSubmit={props.handleDeleteAccount}
        surveyType={'ProfileDeletion'}
      ></FeedbackMessageSurvey>
    </>
  )
}

export default function EditAccountModal(props: ViewModelProps) {
  const viewModel = useViewModel(props)
  return <EditAccountModalComponent {...viewModel} />
}
