import ManageActionCard from '@/authenticated/components/ManageActionCard'
import { useState } from 'react'
import { useAppSelector } from '@/hooks'
import IconItemizedList from '@/authenticated/components/IconItemizedList'
import { AtSign, Smartphone, User } from 'react-feather'
import EditAccountModal from './EditAccountModal'
import { selectUserEmail, selectUserFullName } from '@/selectors'
import { useIsExperimentalUser } from '@/authenticated/hooks/useIsExperimentalUser'
import { Button } from '@/components'
import AdminPanel from 'features/settings/ManageProfileCard/AdminPanel'

function ManageProfileCardComponent() {
  const [editAccountModalOpen, setEditAccountModalOpen] = useState(false)
  const name = useAppSelector(selectUserFullName)
  const email = useAppSelector(selectUserEmail)
  const isExperimental = useIsExperimentalUser()
  const [openAdminPanel, setOpenAdminPanel] = useState(false)

  const requiredUserElements = [
    {
      text: name ?? '',
      icon: User,
    },
    {
      text: email ?? '',
      icon: AtSign,
    },
  ]

  const additionalMobileElements = [
    {
      text: window.appVersion ?? '',
      icon: Smartphone,
    },
  ]

  const userElements = [
    ...requiredUserElements,
    ...(window.appVersion ? additionalMobileElements : []),
  ]

  return (
    <ManageActionCard
      title="User Profile"
      id="user-profile-card"
      action={() => setEditAccountModalOpen(true)}
    >
      <IconItemizedList items={userElements} />
      <EditAccountModal
        open={editAccountModalOpen}
        onClose={() => setEditAccountModalOpen(false)}
      />
      {isExperimental && (
        <Button
          id="open-admin-panel"
          variant="click-wrapper"
          onClick={() => setOpenAdminPanel(true)}
        >
          <div className="underline text-themed-blue-500">Admin Panel</div>
        </Button>
      )}
      <AdminPanel
        open={openAdminPanel}
        onClose={() => setOpenAdminPanel(false)}
      />
    </ManageActionCard>
  )
}

export default function ManageProfileCard() {
  const userLoaded = useAppSelector((state) => Boolean(state.user.user))

  if (!userLoaded) {
    return null
  }

  return <ManageProfileCardComponent />
}
