import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import DashboardCard, { DashboardCardProps } from './DashboardCard'

const useStyles = makeStyles(() =>
  createStyles({
    cardRoot: {
      marginBottom: 0,
    },
  }),
)

export interface Props extends DashboardCardProps {}

export default function ProfileCard({ children, ...rest }: Props) {
  const classes = useStyles()

  return (
    <DashboardCard cardClasses={{ root: classes.cardRoot }} {...rest}>
      {children}
    </DashboardCard>
  )
}
