import React from 'react'
import TitledProfileCard from '../TitledProfileCard'
import UtilitySelectTaskCardContent from './UtilitySelectTaskCardContent'

export default function UtilitySelectTaskCard() {
  return (
    <TitledProfileCard title="Select Utility">
      <UtilitySelectTaskCardContent />
    </TitledProfileCard>
  )
}
