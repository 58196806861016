import React from 'react'
import { Box } from '@material-ui/core'
import { useAppSelector } from '../../hooks'
import PlanSettingsManagementCard from './PlanSettingsManagementCard/'
import PlanSelectTaskCard from './PlanSelectTaskCard'
import ErrorAlerts from './ErrorAlerts'
import { clearPlanErrors } from '../../actions/plans'

export default function UtilityPlanCard() {
  const selectedUtility = useAppSelector(
    (state) => state.utilities.selectedUtility,
  )
  const selectedPlan = useAppSelector((state) => state.plans.selectedPlan)

  if (!selectedUtility) {
    return null
  }
  return (
    <Box>
      {selectedPlan ? <PlanSettingsManagementCard /> : <PlanSelectTaskCard />}
      <ErrorAlerts
        clearableErrors={[
          {
            selector: (state) => state.utilities.errors,
            clearAction: clearPlanErrors,
          },
        ]}
      />
    </Box>
  )
}
