import { Card, Text } from '@/components'
import InfoCard from '@/app/components/InfoCard/InfoCard'
import { useNavigation } from '@/app/hooks'
import Headphones from '@/app/img/headphones.svg?react'

export default function SupportCard() {
  const { push: navigateTo } = useNavigation()

  return (
    <Card>
      <div className="flex gap-2 mb-5">
        <Text variant="subheader">Support</Text>
      </div>

      <InfoCard
        title="Contact Us"
        onClick={() => navigateTo('/contact-us')}
        variant="small"
        image={<Headphones className={'mr-3 flex items-center'} />}
      />
    </Card>
  )
}
