import { DeletedVehicle } from '@/types/deletedVehicle'
import { DeletedThermostat } from '@/reducers/deletedThermostat'
import { DeletedVehicleCharger } from '@/reducers/deletedVehicleCharger'

export type DeletedDevice =
  | Partial<DeletedVehicle>
  | Partial<DeletedThermostat>
  | Partial<DeletedVehicleCharger>

export function isDeletedVehicle(
  device: DeletedDevice,
): device is DeletedVehicle {
  return (device as DeletedVehicle).vehicle !== undefined
}

export function isDeletedThermostat(
  device: DeletedDevice,
): device is DeletedThermostat {
  return (device as DeletedThermostat).thermostat !== undefined
}

export function isDeletedVehicleCharger(
  device: DeletedDevice,
): device is DeletedVehicleCharger {
  return (device as DeletedVehicleCharger).charger !== undefined
}
