import { doLogout } from '@/actions/auth'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { useAppDispatch } from '@/hooks'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import { logEvent } from '@/logging'

function useViewModel() {
  const dispatch = useAppDispatch()

  const handleLogout = () => {
    doLogout(dispatch)
    window.location.reload()
  }

  return {
    handleLogout,
  }
}

function useMockViewModel() {
  return {
    handleLogout: () => alert('Logged out'),
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
