import { createTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import profileTheme from './theme'
import v2Theme from '../../../v2/theme'
import Profile from './Profile'
import BrandedHeader from '@/app/components/NavigationPage/WhitelabeledNavHeader/BrandedHeader'

const ThemedProfile = ({ ...props }) => {
  return (
    <ThemeProvider
      theme={(theme) =>
        createTheme({
          ...v2Theme,
          palette: {
            ...v2Theme.palette,
            ...profileTheme.palette,
          },
          overrides: {
            ...v2Theme.overrides,
            ...profileTheme.overrides,
          },
        })
      }
    >
      <BrandedHeader containerClassName="mb-[-6px]" />
      <Profile {...props} />
    </ThemeProvider>
  )
}

export default ThemedProfile
