import React from 'react'
import UtilitySettingsManagementCardContent from './UtilitySettingsManagementCardContent'
import ManageActionCard from '../ManageActionCard'
import { useHistory } from 'react-router-dom'

const UtilitySettingsManagementCard = () => {
  const history = useHistory()

  return (
    <ManageActionCard
      title="Utility Provider"
      id="utility-provider-card"
      action={() => {
        history.push('/connect-utility/select')
      }}
    >
      <UtilitySettingsManagementCardContent />
    </ManageActionCard>
  )
}

export default UtilitySettingsManagementCard
