import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '@/components'
import {
  Box as MuiBox,
  CircularProgress as MuiCircularProgress,
  Typography,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'
import AddHomeDialog from '../Home/AddHomeDialog'
import { useAppSelector, useUrlSearchParam } from '@/hooks'
import { useHistory } from 'react-router-dom'

const Centered = styled.div`
  text-align: center;
`
const Box = styled(MuiBox)(spacing)
const CircularProgress = styled(MuiCircularProgress)(spacing)

export default function HomeSelectTask() {
  const action = useUrlSearchParam('action')
  const history = useHistory()
  const [open, setOpen] = useState(action === 'home' ? true : false)
  const isLoading = useAppSelector((state) => state.homes.isLoading)
  const errors = useAppSelector((state) => state.homes.errors)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    history.replace({ search: '' })
    setOpen(false)
  }

  if (errors.detail) {
    setOpen(true)
  }

  return (
    <Box>
      <Typography variant="body2" gutterBottom>
        Provide your address so we can identify when you have plugged in at
        home. This also allows us to find your utility provider.
      </Typography>
      {isLoading ? (
        <Centered>
          <CircularProgress m={2} color="secondary" />
        </Centered>
      ) : (
        <Box mt={3}>
          <div>
            <Centered>
              <Button
                variant="primary"
                onClick={handleClickOpen}
                id="update-home-address-button"
              >
                Update
              </Button>
            </Centered>
            <AddHomeDialog open={open} onClose={handleClose} />
          </div>
        </Box>
      )}
    </Box>
  )
}
