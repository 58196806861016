import React from 'react'
import styled from 'styled-components'
import {
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Typography,
  Box as MuiBox,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { useAppSelector } from '../../../hooks'
import { useHistory } from 'react-router-dom'

const Box = styled(MuiBox)(spacing)
const Button = styled(MuiButton)(spacing)
const CircularProgress = styled(MuiCircularProgress)(spacing)
const Centered = styled.div`
  text-align: center;
`

export default function UtilitySelectTaskCardContent() {
  const history = useHistory()
  const { isLoading: homeIsLoading } = useAppSelector((state) => state.homes)
  const { isLoading: utilitiesAreLoading } = useAppSelector(
    (state) => state.utilities,
  )

  const handleClickUpdate = () => {
    history.push('/connect-utility/select')
  }

  return (
    <Box>
      <Typography variant="body2" gutterBottom>
        Select your utility provider from the drop-down menu. This provides your
        electricity plan options.
      </Typography>
      {homeIsLoading || utilitiesAreLoading ? (
        <Centered>
          <CircularProgress m={2} color="secondary" />
        </Centered>
      ) : (
        <Box mt={3}>
          <div>
            <Centered>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickUpdate}
              >
                Update
              </Button>
            </Centered>
          </div>
        </Box>
      )}
    </Box>
  )
}
