import { Button } from '@/components'
import formatClasses from '@/utils/classes/formatClasses'
import './styles.scss'

export interface Props {
  handleLogout: () => void
}

export default function AccountCard(props: Props) {
  const classes = {
    button: formatClasses(['account-card-button']),
  }

  return (
    <Button
      variant="primary-subtle"
      className={classes.button}
      onClick={props.handleLogout}
      id="account-card-logout-button"
    >
      Logout
    </Button>
  )
}
