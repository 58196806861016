import { Button, Modal, Text } from '@/components'
import XListItem from '@/components/XListItem/XListItem'
import { Link } from 'app-components'
import { DeviceType } from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import { match } from 'ts-pattern'

type Props = {
  deviceName: string
  deviceType: DeviceType
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const vehicleList = (
  <>
    <XListItem text="Cancel all scheduled charging and preconditioning" />
    <XListItem text="Stop collecting all vehicle data" />
    <XListItem text="Delete saved trips" />
    <XListItem text="End program rewards" />
  </>
)

const thermostatList = (
  <>
    <XListItem text="Cancel all schedules, pre-cooling, and pre-heating" />
    <XListItem text="Stop collecting all thermostat data" />
    <XListItem text="End program rewards" />
  </>
)

const chargerList = (
  <>
    <XListItem text="Stop collecting all vehicle data" />
    <XListItem text="End program rewards" />
  </>
)

const meterList = (
  <>
    <XListItem text="Stop collecting all meter data" />
    <XListItem text="End program rewards" />
  </>
)

const DeviceDeletionModal = ({
  deviceName,
  deviceType,
  open,
  onClose,
  onSubmit = () => {},
}: Props) => {
  return (
    <Modal
      id="device-deletion-modal"
      title="Are you sure?"
      open={open}
      onClose={onClose}
    >
      <Text variant="body4">
        Deleting your {deviceName} will permanently remove it, and:
      </Text>
      <div className="flex flex-col gap-1 py-2">
        {match(deviceType)
          .with('vehicle', () => vehicleList)
          .with('thermostat', () => thermostatList)
          .with('vehicleCharger', () => chargerList)
          .with('home', () => meterList)
          .exhaustive()}
      </div>
      <Text variant="body4">
        {' '}
        If you need help, our support team can assist you within 48 hours.
        Contact us at{' '}
        <Link href="mailto:support@optiwatt.com">
          support@optiwatt.com
        </Link> or <Link href="tel:855-967-1267">855-967-1267</Link>.
      </Text>
      <Modal.Actions>
        <Button id="back-btn" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button id="delete-btn" variant="primary" onClick={onSubmit}>
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeviceDeletionModal
