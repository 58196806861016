import React from 'react'
import TitledProfileCard from '../TitledProfileCard'
import HomeSelectTaskCardContent from './HomeSelectTaskCardContent'

export default function HomeSelectTaskCard() {
  return (
    <TitledProfileCard title="Add Home">
      <HomeSelectTaskCardContent />
    </TitledProfileCard>
  )
}
