import React from 'react'
import { Box as MuiBox, Typography as MuiTypography } from '@material-ui/core'
import * as Icon from 'react-feather'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'

const Box = styled(MuiBox)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      color: theme.palette.gray?.contrastText ?? '#5D5D5D',
      paddingRight: theme.spacing(1),
      verticalAlign: 'middle',
    },
    link: {
      color: theme.palette.blue?.main ?? '#426FFB',
    },
    itemText: {
      fontSize: 14,
      marginLeft: theme.spacing(2),
      color: theme.palette.secondary.contrastText,
      display: 'inline-block',
      maxWidth: '80%',
      verticalAlign: 'middle',
    },
  }),
)

export type IconItem = {
  icon: React.ComponentType<Icon.IconProps>
  text: string | React.ReactNode
}

export interface Props {
  iconSize?: number
  items: IconItem[]
}

export default function IconItemizedList({ iconSize = 24, items }: Props) {
  const classes = useStyles()

  return (
    <Box>
      {items.map(({ icon: Icon, text }, index) => (
        <Box key={index} mb={3}>
          <Icon size={iconSize} className={classes.icon} />
          <Typography variant="subtitle2" className={classes.itemText}>
            {text}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
