import React from 'react'
import { Box } from '@material-ui/core'
import { useAppSelector } from '../../hooks'
import UtilitySettingsManagementCard from './UtilitySettingsManagementCard'
import UtilitySelectTaskCard from './UtilitySelectTaskCard'
import ErrorAlerts from './ErrorAlerts'
import { clearUtilityErrors } from '../../actions/utilities'

export default function UtilityProviderCard() {
  const selectedHome = useAppSelector((state) => state.homes.selectedHome)
  const selectedUtility = useAppSelector(
    (state) => state.utilities.selectedUtility,
  )

  if (!selectedHome) {
    return null
  }
  return (
    <Box>
      {selectedUtility ? (
        <UtilitySettingsManagementCard />
      ) : (
        <UtilitySelectTaskCard />
      )}
      <ErrorAlerts
        clearableErrors={[
          {
            selector: (state) => state.utilities.errors,
            clearAction: clearUtilityErrors,
          },
        ]}
      />
    </Box>
  )
}
