import { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import {
  Button as MuiButton,
  Paper,
  Typography as MuiTypography,
} from '@material-ui/core'
import { Layers } from 'react-feather'
import { spacing } from '@material-ui/system'
import { useDispatch, useSelector } from 'react-redux'
import SelectPlanDropdown from './SelectPlanDropdown'
import SelectPlanBillUpload from './SelectPlanBillUpload'
import SelectManualPlan from './ManualPlan/SelectManualPlan'
import { endEditPlan } from '../../../actions/manualPlans'
import Loader from '../layout/Loader'

const Button = styled(MuiButton)(spacing)

const Typography = styled(MuiTypography)(spacing)

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`

const BigLayers = styled(Layers)`
  width: 45px;
  height: 45px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`

function SelectPlan({ cancelOption, ...restProps }) {
  const { selectedUtility, plans } = useSelector((state) => state.utilities)
  let [dropDownSelected, setDropDownSelected] = useState(false)
  let [uploadSelected, setUploadSelected] = useState(false)
  let [manualSelected, setManualSelected] = useState(false)
  const { isLoading } = useSelector((state) => state.plans)
  const dispatch = useDispatch()

  if (isLoading) {
    return <Loader />
  }

  if (dropDownSelected) {
    return (
      <SelectPlanDropdown
        onCancel={() => setDropDownSelected(false)}
        {...restProps}
      />
    )
  } else if (uploadSelected) {
    return (
      <SelectPlanBillUpload
        onCancel={() => setUploadSelected(false)}
        {...restProps}
      />
    )
  } else if (manualSelected) {
    return (
      <SelectManualPlan
        onCancel={() => setManualSelected(false)}
        {...restProps}
      />
    )
  }

  const hasPlans = plans?.length > 0
  return (
    <Wrapper justifyContent="center" align="center" {...restProps}>
      <BigLayers />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {selectedUtility.name}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        How would you like to find your utility plan?
      </Typography>
      <Button
        onClick={() => setUploadSelected(true)}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        mb={2}
        mt={5}
      >
        Upload utility bill
      </Button>
      <Button
        onClick={() => setManualSelected(true)}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        mb={2}
      >
        Add utility plan manually
      </Button>
      {hasPlans && (
        <Button
          onClick={() => setDropDownSelected(true)}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          mb={2}
        >
          Select plan from drop-down list
        </Button>
      )}
      {cancelOption && (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="default"
          onClick={() => {
            dispatch(endEditPlan())
          }}
        >
          Back
        </Button>
      )}
    </Wrapper>
  )
}

SelectPlan.defaultProps = {
  cancelOption: false,
}

export default withTheme(SelectPlan)
