import styled, { withTheme } from 'styled-components'
import {
  Button as MuiButton,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography as MuiTypography,
} from '@material-ui/core'
import { Layers } from 'react-feather'
import { spacing } from '@material-ui/system'
import { uploadUtilityBillForm } from '../../../actions/plans'
import { useDispatch, useSelector } from 'react-redux'
import { CloudUpload as MuiCloudUpload } from '@material-ui/icons'
import ContactUs from '@/app/components/ContactUs/ContactUs'

const Button = styled(MuiButton)(spacing)

const Typography = styled(MuiTypography)(spacing)

const CloudUpload = styled(MuiCloudUpload)(spacing)

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`

const BigLayers = styled(Layers)`
  width: 45px;
  height: 45px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`

function SelectPlanBillUpload({ onCancel, ...restProps }) {
  const dispatch = useDispatch()
  const { selectedUtility: utility, plans } = useSelector(
    (state) => state.utilities,
  )
  const { isLoading, errors } = useSelector((state) => state.plans)

  const handleSubmit = (event) => {
    const data = new FormData()
    data.append('file', event.target.files[0])
    dispatch(uploadUtilityBillForm(data))
  }

  function getFindBillHeader(steps) {
    if (steps.length === 0) {
      return 'Please upload your utility bill. This integrates your exact electricity rates.'
    } else if (steps.length === 1) {
      return "Please sign in to your utility's website and upload your bill."
    } else {
      return 'Please follow these steps to find your utility bill.'
    }
  }

  function getUrl(utility) {
    let url = utility.login_url
    if (url === null) {
      url = utility.website
    }

    if (!/^https?:\/\//i.test(url)) {
      url = 'http://' + url
    }

    return url
  }

  function getFindBillSteps(utility) {
    let url = getUrl(utility)
    return (
      <List>
        {url && (
          <ListItem>
            <ListItemText>
              1. Sign in to your utility's website
              <a href={url} target="_blank" rel="noopener noreferrer">
                {' '}
                here
              </a>
            </ListItemText>
          </ListItem>
        )}

        {utility.find_bill_steps.map((step, index) => (
          <ListItem>
            <ListItemText>
              {index + 2 + '. ' + step['step_description']}{' '}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      align="center"
      {...restProps}
    >
      <BigLayers />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {utility.name}
      </Typography>

      <Typography component="h2" variant="body1" align="center">
        {getFindBillHeader(utility.find_bill_steps)}
      </Typography>

      {errors.detail ? (
        <div className="alert alert-danger" role="alert">
          {errors.detail}
        </div>
      ) : (
        ''
      )}
      <form
        autoComplete="off"
        method="POST"
        id="addHomeForm"
        className="add-home-form"
      >
        {getFindBillSteps(utility)}
        {isLoading ? (
          <CircularProgress m={2} color="secondary" />
        ) : (
          <div>
            <input
              onChange={handleSubmit}
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
            />
            <label htmlFor="raised-button-file" style={{ width: '100%' }}>
              <Button
                data-ga-event-label="upload-bill"
                variant="contained"
                fullWidth
                color="primary"
                component="span"
                mb={2}
                mt={2}
              >
                <CloudUpload mr={2} /> Upload Bill
              </Button>
            </label>
            <Button
              onClick={onCancel}
              type="submit"
              fullWidth
              variant="contained"
              mb={2}
            >
              Cancel
            </Button>
            <Typography component="h2" variant="body1" align="center" mt={2}>
              Want another option? Please <ContactUs />.
            </Typography>
          </div>
        )}
      </form>
    </Wrapper>
  )
}

SelectPlanBillUpload.defaultProps = {
  onClose: () => {},
  onCancel: () => {},
}

export default withTheme(SelectPlanBillUpload)
